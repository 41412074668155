export default {
  totalAmountMarginTop: '3rem',
  loanCalculatorBorderWidth: '1px',
  loanCalculatorValueColor: 'var(--digi-blue-400)',
  summaryButtonSelectedBackground: 'var(--digi-blue-400)',
  summaryButtonSelectedColor: 'var(--white)',
  userAgreementMarginTop: '.5rem',
  prefilllColor: 'var(--digi-blue-700)',
  prefillBackroundColor: 'var(--white)',
  validatedBrandColor: 'var(--digi-blue-400)',
  buttonText: 'var(--white) !important',
};
